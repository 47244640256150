.App {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.highlight:hover {
  color: white;
}
